import { Component } from 'react'
import './index.css'

class UpdatePassword extends Component {
    state = { reEnterPassword: '', password: '', showSubmitError: '', email: '', token: '' }

    componentDidMount() {
        const searchParams = new URLSearchParams(this.props.location.search)
        const email = searchParams.get('email')
        const token = searchParams.get('token')
        this.setState({ email, token })
    }

    onChangeReEnterPassword = event => this.setState({ reEnterPassword: event.target.value })

    onChangePassword = event => this.setState({ password: event.target.value })

    onSuccessReset = () => {
        const {history} = this.props
        history.replace('/login')
    }

    onSubmitResetForm = async event => {
        event.preventDefault()
        const { reEnterPassword, password, email, token } = this.state

        // Validation
        if (reEnterPassword === '' || password === '') {
            this.setState({ showSubmitError: 'Password is required' })
            return
        }
        
        if(reEnterPassword!==password){
            this.setState({ showSubmitError: 'both are not matched' })
            return 
        }

        console.log(password)
        const encodedPass = btoa(password);

        console.log(encodedPass)
        console.log(email, token)
        const userDetails = {password}
        const url = `${process.env.REACT_APP_BASE_URL}/password/reset-password/${email}/${token}`
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userDetails),
        }
        const response = await fetch(url, options)
        const data = await response.json()
        console.log(response)
        if (response.ok === true) {
            this.onSuccessReset()
        } else {
            console.log(data)
            this.setState({showSubmitError: data.msg})
        }
    }

    render() {
        const { showSubmitError, reEnterPassword, password, } = this.state
        return (
            <div className='login-container'>
                <form className='form-container' onSubmit={this.onSubmitResetForm}>
                    <img src="https://res.cloudinary.com/dwma8dkv0/image/upload/v1719837097/a9w6thfdqx3hs0xbk4gg.jpg" alt="logo" className='login-page-img' />
                    <div className='form-content'>
                        <div className='input-set'>
                            <label className='label' htmlFor="password">PASSWORD</label><br />
                            <input className='input' value={password} onChange={this.onChangePassword} placeholder='Password' type="password" id="password" />
                        </div>
                        <div className='input-set'>
                            <label className='label' htmlFor="reEnterPassword">Re-Enter Password</label><br />
                            <input className='input' value={reEnterPassword} onChange={this.onChangeReEnterPassword} placeholder='Value' type="password" id="reEnterPassword" />
                        </div>
                        <button type="submit" className='login-btn'>Reset</button>
                        {showSubmitError && <p className="login-err">{showSubmitError}</p>}
                    </div>
                </form>
            </div>
        )
    }
}

export default UpdatePassword