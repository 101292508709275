import { lazy, Suspense } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import { TailSpin } from 'react-loader-spinner'
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import './App.css'
import EmailVerification from './components/EmailVerifcation'
import UpdatePassword from './components/UpdatePassword'
import ProtectedRoute from './components/ProtectedRoute'

const Home = lazy(() => import('./components/Home'))
const History = lazy(() => import('./components/History'))
const LoginPage = lazy(() => import('./components/LoginPage'))
const RegisterPage = lazy(() => import('./components/RegisterPage'))
const ServiceItemDetails = lazy(() => import('./components/ServiceItemDetails'))
const OrderingPage = lazy(() => import('./components/OrderingPage'))
const ConfirmOrder = lazy(() => import('./components/ConfirmOrderPage'))
const OrdersPage = lazy(() => import('./components/OrdersPage'))
const AboutPage = lazy(() => import('./components/AboutPage'))
const MobileVerification = lazy(() => import('./components/MobileVerification'))
const TransactionsHistory = lazy(() => import('./components/TransactionsHistory'))

const LoadingView = () => (
  <div className="loading-section" data-testid="loader">
    <TailSpin color="#0b69ff" height={80} width={80} />
  </div>
)

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Suspense fallback={<LoadingView />}>
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/verify" component={EmailVerification} />
            <Route exact path='/update-password' component={UpdatePassword} />
            <Route exact path='/' component={Home} />
            <Route exact path='/history' component={History} />
            <Route exact path='/policy' component={AboutPage} />
            <Route exact path='/orders' component={OrdersPage} />
            <Route exact path='/transactions' component={TransactionsHistory} />
            <Route exact path='/mobile-verification' component={MobileVerification} />
            <Route exact path='/service/:id' component={ServiceItemDetails} />
            <Route exact path='/service/:id/confirm-order' component={ConfirmOrder} />
            <ProtectedRoute exact path='/service/:id/order' component={OrderingPage} />
          </Switch>
        </Suspense>
      </BrowserRouter>
    </div>
  )
}

export default App;
