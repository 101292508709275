import { Component } from 'react'
import './index.css'

class EmailVerification extends Component {
    state = { email: '',showSubmitError:'',verify:false}

    onChangeEmail = event => this.setState({ email: event.target.value })

    onSubmitLoginForm = async event => {
        event.preventDefault()
        const { email } = this.state

        // Validation
        if (email === '') {
            this.setState({ showSubmitError: 'email are required' })
            return
        }   

        const userDetails = { email}
        const url = `${process.env.REACT_APP_BASE_URL}/password/forgot-password`
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userDetails),
        }
        const response = await fetch(url, options)
        const data = await response.json()
        if (response.ok === true) {
            console.log(data)
            this.setState(prevState => ({verify:!prevState.verify}))
            //this.onSuccessLogin(data.token) //check for token
        } else {
            console.log(data)
            console.log("error")
            //this.setState({showSubmitError: data.msg})
        }
    }

    render() {
        const { showSubmitError, email,verify} = this.state
        return (
            <div className='login-container'>
                <form className='form-container' onSubmit={this.onSubmitLoginForm}>
                    <img src="https://res.cloudinary.com/dwma8dkv0/image/upload/v1719837097/a9w6thfdqx3hs0xbk4gg.jpg" alt="logo" className='login-page-img' />
                    {verify ? <h1>Check Your Gmail</h1> :<div className='form-content'>
                        <div className='input-set'>
                            <label className='label' htmlFor="email">EMAIL</label><br />
                            <input className='input' value={email} onChange={this.onChangeEmail} placeholder='Value' type="text" id="email" />
                        </div>
                        <button type="submit" className='login-btn'>Verify</button>
                        {showSubmitError && <p className="login-err">{showSubmitError}</p>}
                    </div>}
                </form>
            </div>
        )
    }
}

export default EmailVerification